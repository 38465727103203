let localization;

let localizationDefault = {
    toCountryButtonText: "To Biltema Sweden",
    defaultCountryUrl: "https://www.biltema.se"
};

let localizationSV = {
    toCountryButtonText: "Till Biltema Sverige",
    defaultCountryUrl: "https://www.biltema.se"
};

let localizationNB = {
    toCountryButtonText: "Til Biltema Norge",
    defaultCountryUrl: "https://www.biltema.no"
};

let localizationFI = {
    toCountryButtonText: "Biltema Suomen verkkosivuille",
    defaultCountryUrl: "https://www.biltema.fi"
};

let localizationDK = {
    toCountryButtonText: "Til Biltema Danmark",
    defaultCountryUrl: "https://www.biltema.dk"
};

let currentUserLocale = navigator.language.toLowerCase().substring(0, 2);

if (currentUserLocale == "sv") {
    localization = localizationSV;
}
else if (currentUserLocale == "nb") {
    localization = localizationNB;
}
else if (currentUserLocale == "fi") {
    localization = localizationFI;
}
else if (currentUserLocale == "da") {
    localization = localizationDK;
}
else {
    localization = localizationDefault;
}

document.getElementById("biltemaRealEstate").href = localization.defaultCountryUrl + "/biltemarealestate";
document.getElementById("toCountryButton").href = localization.defaultCountryUrl;
document.getElementsByClassName("button")[0].innerHTML = "<span>" + localization.toCountryButtonText + "</span>";