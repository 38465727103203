//let initializeLocalization = function () {
//    let localization;

//    let localizationDefault = {
//        title: "Welcome to Biltema!"
//    }

//    let localizationSvSe = {
//        title: "Välkommen till Biltema!"
//    }

//    let currentUserLocale = navigator.language.toLowerCase();

//    console.log("Current user locale is: " + currentUserLocale);

//    switch (currentUserLocale) {
//        case "sv-se":
//            localization = localizationSvSe;
//        default:
//            localization = localizationDefault;
//    }

//    document.querySelector(".heading--l").innerHTML = localization.title;
//};